import React from "react";

const NotFoundPage = () => {
    return (
        <div className="not-found-main">
            <div className="not-found-content">
                <h1>Oops, looks like you got lost :-(</h1>
            </div>

        </div>

    );
}

export default NotFoundPage
import React from "react";

const NobleUnitConverterPrivacyPolicy = () => {
    const accountDeletionRequestEmail = process.env.REACT_APP_ACCOUNT_DELETION_REQUEST_EMAIL;
    const contactUsEmail = process.env.REACT_APP_CONTACT_US_EMAIL;
    return (
        <div className="privacy-policy-main-container">
            <h3>Privacy Policy</h3>
            <h1 className="privacy-policy-main-title">Noble Unit Converter</h1>
            <p><b>Last updated 7 April 2024</b></p>
            <p>
                This privacy policy applies to the Noble Unit Converter app (hereby referred to as "Application") for mobile
                devices that was created by Noble Notch Developers (hereby referred to as "Service Provider") as a Free
                service. This service is intended for use "AS IS".
            </p>
            <h4>What information does the Application obtain and how is it used?</h4>
            <p>The Application does not obtain any information when you download and use it. Registration is not
                required to use the Application.</p>
            <h4>Does the Application collect precise real time location information of the device?</h4>
            <p>This Application does not collect precise information about the location of your mobile device.</p>
            <h4>Do third parties see and/or have access to information obtained by the Application?</h4>
            <p>Since the Application does not collect any information, no data is shared with third parties.</p>
            <h4>What are my opt-out rights?</h4>
            <p>You can stop all collection of information by the Application easily by uninstalling it. You may use the
                standard uninstall processes as may be available as part of your mobile device or via the mobile
                application marketplace or network. You can also request us to delete your data by sending an email
                to <a
                    href={"mailto:" + accountDeletionRequestEmail}>{accountDeletionRequestEmail}</a></p>
            <h4>Children</h4>
            <p>The Application is not used to knowingly solicit data from or market to children under the age of 13.</p>
            <p>The Service Provider does not knowingly collect personally identifiable information from children. The
                Service Provider encourages all children to never submit any personally identifiable information through
                the Application and/or Services. The Service Provider encourage parents and legal guardians to monitor
                their children's Internet usage and to help enforce this Policy by instructing their children never to
                provide personally identifiable information through the Application and/or Services without their
                permission. If you have reason to believe that a child has provided personally identifiable information
                to the Service Provider through the Application and/or Services, please contact the Service Provider
                (<a
                    href={"mailto:" + contactUsEmail}>{contactUsEmail}</a>) so that they will be able to take the necessary actions. You must also
                be at least 16 years of age to consent to the processing of your personally identifiable information in
                your country (in some countries we may allow your parent or guardian to do so on your behalf).</p>
            <h4>Security</h4>
            <p>The Service Provider is concerned about safeguarding the confidentiality of your information. However,
                since the Application does not collect any information, there is no risk of your data being accessed by
                unauthorized individuals.</p>
            <h4>Changes</h4>
            <p>This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you
                of any changes to their Privacy Policy by updating this page with the new Privacy Policy. You are
                advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of
                all changes.</p>
            <h4>Your Consent</h4>
            <p>By using the Application, you are consenting to the processing of your information as set forth in this
                Privacy Policy now and as amended by the Service Provider.</p>
            <h4>Contact Us</h4>
            <p>If you have any questions regarding privacy while using the Application, or have questions about the
                practices, please contact the Service Provider via email at <a
                    href={"mailto:" + contactUsEmail}>{contactUsEmail}</a></p>
        </div>
    );
}

export default NobleUnitConverterPrivacyPolicy;
import React from "react";
import LatestProductScreenshot from "../../assets/meal_timetable_screenshot.png";
import GooglePlayBadge from "../../assets/google_play_badge.png";

const LatestProduct = () => {

    const styleLatestProductImage = {
        maxHeight: "440px"
    };

    return (<div id="latestProduct" className="latest-product-main">
        <h3>Products</h3>
        <h1>Latest Product</h1>
        <div className="latest-product-container">
            <div className="latest-product-column">
                <img style={styleLatestProductImage} src={LatestProductScreenshot} alt="Latest Product Screenshot"/>
            </div>
            <div className="latest-product-column">
                <h3>Meal Timetable</h3>
                <p>Plan Your Week's Meals Effortlessly</p>
                <a href="https://play.google.com/store/apps/details?id=com.noblenotch.mealtimetable" target="_blank" rel="noreferrer">
                    <img style={{marginTop: "24px"}} className="google-play-badge" src={GooglePlayBadge}
                         alt="Download On PlayStore"/>
                </a>
            </div>
        </div>
    </div>);
}

export default LatestProduct
import './App.css';
import LandingPage from "./pages/LandingPage";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Footer from "./components/Footer";
import OurRecipesPrivacyPolicy from "./pages/privacypolicy/OurRecipesPrivacyPolicy";
import MealTimetablePrivacyPolicy from "./pages/privacypolicy/MealTimetablePrivacyPolicy";
import NobleDriftPrivacyPolicy from "./pages/privacypolicy/NobleDriftPrivacyPolicy";
import NobleUnitConverterPrivacyPolicy from "./pages/privacypolicy/NobleUnitConverterPrivacyPolicy";
import SpeedTapPrivacyPolicy from "./pages/privacypolicy/SpeedTapPrivacyPolicy";
import VideoToMp3PrivacyPolicy from "./pages/privacypolicy/VideoToMp3PrivacyPolicy";
import NotFoundPage from "./pages/NotFoundPage";
import AccountDeletionRequest from "./pages/AccountDeletionRequest";

function App() {
    const accountDeletionPageLink = process.env.REACT_APP_ACCOUNT_DELETION_PAGE_LINK;
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/"><LandingPage/></Route>
                    <Route exact path="/privacyPolicy/ourRecipes"><OurRecipesPrivacyPolicy/></Route>
                    <Route exact path="/privacyPolicy/mealTimetable"><MealTimetablePrivacyPolicy/></Route>
                    <Route exact path="/privacyPolicy/nobleDrift"><NobleDriftPrivacyPolicy/></Route>
                    <Route exact path="/privacyPolicy/nobleUnitConverter"><NobleUnitConverterPrivacyPolicy/></Route>
                    <Route exact path="/privacyPolicy/speedTap"><SpeedTapPrivacyPolicy/></Route>
                    <Route exact path="/privacyPolicy/videoToMp3"><VideoToMp3PrivacyPolicy/></Route>
                    <Route exact path={"/" + accountDeletionPageLink}><AccountDeletionRequest/></Route>
                    <Route path="*"><NotFoundPage/></Route>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
import React from "react";

const AccountDeletionRequest = () => {
    const accountDeletionRequestEmail = process.env.REACT_APP_ACCOUNT_DELETION_REQUEST_EMAIL;

    return (
        <div className="privacy-policy-main-container" style={{minHeight: "90vh"}}>
            <h3>Account Deletion</h3>
            <h1 className="privacy-policy-main-title">Account Deletion Request</h1>
            <p>We respect your privacy and offer you the option to request the deletion of your account and associated
                data from our app. To initiate this process, simply send an email from the address you used to create
                your account, including the name of the app, to <a
                    href={"mailto:" + accountDeletionRequestEmail}>{accountDeletionRequestEmail}</a> . For security
                reasons,
                we require this verification step. Once we receive your request, we will send you a confirmation email.
                Upon acceptance of this confirmation, your data will be scheduled for deletion within 90 days. Your
                privacy and security are our top priorities, and we appreciate your trust in us.</p>
        </div>
    );
}

export default AccountDeletionRequest;
import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-main">
            <p>&copy; {currentYear} Noble Notch Ltd. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer
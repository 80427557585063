import React from "react";
import Cta from "../components/home/Cta";
import LatestProduct from "../components/home/LatestProduct";
import PreviousWork from "../components/home/PreviousWork";

const LandingPage = () => {
    return (
        <div>
            <Cta/>
            <LatestProduct/>
            <PreviousWork/>
        </div>
    );
}

export default LandingPage;
import React from "react";
import IconMealTimetable from "../../assets/icon_meal_timetable.png";
import IconSpeedTap from "../../assets/icon_speed_tap.png";
import IconOurRecipes from "../../assets/icon_our_recipes.png";
import IconNobleUnitConverter from "../../assets/icon_noble_unit_converter.png";

const PreviousWork = () => {
    return (
        <div className="previous-work-main">
            <h1>Previous Work</h1>
            <div className="previous-work-image-grid">
                <div className="previous-work-image-grid-item">
                    <a href="https://play.google.com/store/apps/details?id=com.noblenotch.mealtimetable" target="_blank"
                       rel="noreferrer">
                        <img src={IconMealTimetable} alt="Meal Timetable"/>
                    </a>
                </div>

                <div className="previous-work-image-grid-item">
                    <a href="https://play.google.com/store/apps/details?id=com.noblenotch.speedtap" target="_blank"
                       rel="noreferrer">
                        <img src={IconSpeedTap} alt="Speed Tap"/>
                    </a>
                </div>

                <div className="previous-work-image-grid-item">
                    <a href="https://play.google.com/store/apps/details?id=com.noblenotch.ourrecipes" target="_blank"
                       rel="noreferrer">
                        <img src={IconOurRecipes} alt="Our Recipes"/>
                    </a>
                </div>

                <div className="previous-work-image-grid-item">
                    <a href="https://play.google.com/store/apps/details?id=com.noble.notch.universalunitconverter"
                       target="_blank" rel="noreferrer">
                        <img src={IconNobleUnitConverter} alt="Noble Unit Converter"/>
                    </a>
                </div>

            </div>
        </div>

    );
}

export default PreviousWork